import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Alerts, Button, Checkbox, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  setCurrentStep,
  setOnboardingData,
  setTenantId,
  setToken,
  setUserProfileId,
} from 'features/onboarding'
import { resetSubscriptions } from 'features/subscriptions'
import { useLogin } from 'framework/api/methods'
import { setToInitial, setUserDetails } from 'store/reducers/user/userSlice'

import { RowWithAlignment } from 'components/atoms/Row'

export const LogInForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [twoFACode, setTwoFACode] = useState<string>('')

  const [alertDetails, setAlertDetails] = useState({
    isActive: false,
    messageHeader: '',
    messageBody: '',
  })
  const [userFormDetails, setUserFormDetails] = useState({
    username: '',
    password: '',
  })
  const [isTwoFARequired, setIsTwoFARequired] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMeStatus, setRememberMeStatus] = useState(true)

  /** Get Local Token */
  const LocalStorageToken = localStorage.getItem('token')
  const sessionStorageToken = sessionStorage.getItem('token')
  const token = LocalStorageToken || sessionStorageToken

  const [searchParams] = useSearchParams()
  const returnUrl = searchParams.get('returnUrl') || '/'

  const isCheckListExist = localStorage.getItem('isChecklist')

  /** Redirect user to Homepage if Token exists */
  useEffect(() => {
    if (token) {
      navigate('/', { replace: true })
    }
  })

  /** Log In User API Call ------> Mutate */
  const { mutate: logInMutate, isLoading: logInIsLoading } = useLogin()

  /** Process the Login submission */
  async function logIn(userData: any) {
    logInMutate(
      isTwoFARequired
        ? {
            ...userData,
            code: twoFACode,
          }
        : userData,
      {
        onSuccess: ({ data: successData }: any) => {
          if (successData.requiresTwoFactor) {
            setIsTwoFARequired(true)
            setAlertDetails({
              isActive: false,
              messageHeader: '',
              messageBody: '',
            })
          } else {
            const { status, currentStep, stepperData } =
              successData.onboardingStepperStatus
            if (status == 'InProgress' && stepperData) {
              dispatch(setCurrentStep(currentStep))
              dispatch(setOnboardingData(stepperData))
              dispatch(setTenantId(successData.userProfile.tenantId))
              dispatch(setUserProfileId(successData.userProfile.id))
              dispatch(setToken(successData.token))
              sessionStorage.setItem('onboardingToken', successData.token)
              navigate('/auth/sign-up')
            } else {
              localStorage.setItem('token', successData.token)
              localStorage.setItem('isFirstAttempt', successData.isFirstAttempt)
              localStorage.setItem('isCreditLow', successData.isCreditLow)
              localStorage.setItem(
                'isZeroCreditLow',
                successData.isZeroCreditLow,
              )
              // localStorage.setItem(
              //   'isAssessmentCreditZero',
              //   successData.isAssessmentCreditZero,
              // )
              // localStorage.setItem(
              //   'isAssessmentCreditLow',
              //   successData.isAssessmentCreditLow,
              // )
              sessionStorage.setItem('onboardingToken', '')

              if (rememberMeStatus) {
                localStorage.setItem('refreshToken', successData.refreshToken)
              }
              localStorage.setItem('rememberMe', String(rememberMeStatus))
              dispatch(setUserDetails(successData.userProfile))

              if (returnUrl && returnUrl !== '/') {
                navigate(returnUrl, { replace: true })
              } else if (
                successData?.userProfile?.roleName === 'SuperAdministrator'
              ) {
                navigate('/super-admin', { replace: true })
              } else if (
                successData?.userProfile?.roleName === 'Administrator' &&
                successData.isFirstAttempt
              ) {
                navigate('/agency-profile', { replace: true })
              } else {
                navigate('/candidate-list', { replace: true })
              }

              isCheckListExist == null &&
                localStorage.setItem('isChecklist', 'true')
            }
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          setAlertDetails({
            ...alertDetails,
            isActive: true,
            messageHeader: errData.messageTitle
              ? errData.messageTitle
              : 'Error!',
            messageBody: errData.message,
          })
          dispatch(setToInitial())
        },
      },
    )
  }

  /** handle login button click */
  const handleLogInClick = () => {
    logIn(userFormDetails)
  }

  const rememberMeAction = (status: boolean) => {
    setRememberMeStatus(status)
  }

  return (
    <div className='login-form w-[400px] flex flex-col relative'>
      {/* Log in header */}
      <div className='header-text text-heading-2 font-Medium text-Gray-800'>
        {isTwoFARequired ? 'Two-Factor Authentication' : 'Log in'}
      </div>

      {/* Log in header sub text */}
      <div className='pt-3 header-sub-text text-small font-Regular text-Gray-700'>
        {isTwoFARequired
          ? 'Enter the code provided by the Authenticator App:'
          : 'Welcome back! Please enter your details.'}
      </div>

      {isTwoFARequired ? (
        <TextField
          label='Authentication Code'
          isRequired={true}
          className='w-full mt-10'
          placeholder='6-digit verification code'
          value={twoFACode}
          onChange={(e: any) => setTwoFACode(e.target.value)}
        />
      ) : (
        <>
          {/* username form field */}
          <TextField
            type='text'
            label='Username or email'
            placeholder='your@email.com'
            className='pt-10'
            onChange={(e: any) => {
              setUserFormDetails({
                ...userFormDetails,
                username: e.target.value,
              })
            }}
          />

          {/* password form field */}
          <TextField
            type={showPassword ? 'text' : 'password'}
            label='Password'
            className='pt-6'
            placeholder='••••••••'
            onChange={(e: any) => {
              setUserFormDetails({
                ...userFormDetails,
                password: e.target.value,
              })
            }}
            iconAfter={
              userFormDetails.password !== '' ? (
                <i
                  className={CN('hover:cursor-pointer', {
                    'ri-eye-off-line': showPassword == false,
                    'ri-eye-line': showPassword == true,
                  })}
                />
              ) : (
                <></>
              )
            }
            iconAfterOnClick={() => {
              setShowPassword(!showPassword)
            }}
          />

          {/* options */}
          <div className='flex items-center justify-between pt-5'>
            {/* remember me check box */}
            <Checkbox
              labelText='Remember me'
              helperText=''
              defaultChecked={rememberMeStatus}
              onChange={(e) => {
                rememberMeAction(e.target.checked)
              }}
            />

            {/* forget password link */}
            <Button
              onClick={() => {
                navigate('/auth/forgot-password')
              }}
              textTheme='blue'
              theme='link'>
              Forgot password?
            </Button>
          </div>
        </>
      )}

      {/* login button */}
      <Button
        isBlock
        isDisabled={
          !userFormDetails.username ||
          !userFormDetails.password ||
          (isTwoFARequired && !twoFACode)
        }
        isLoading={logInIsLoading}
        onClick={() => {
          handleLogInClick()
          dispatch(resetSubscriptions())
        }}
        className='mt-6 login-btn'>
        {isTwoFARequired ? 'Verify' : 'Log in'}
      </Button>

      {/* sign up navigation link */}
      {isTwoFARequired ? (
        <div className='flex items-center justify-center w-full pb-2 mt-6 text-small font-Medium'>
          Go back to
          <a href='/auth/login' className='px-1 font-Bold text-Cobalt-600'>
            Log in
          </a>
        </div>
      ) : (
        <div className='flex items-center justify-center w-full mt-6 text-small font-Medium'>
          Still have no account?{' '}
          <a href='/auth/sign-up' className='px-1 font-Bold text-Cobalt-600'>
            Create Account
          </a>{' '}
          now
        </div>
      )}

      {/* error alert */}
      {alertDetails.isActive && (
        <RowWithAlignment justifyAlignment='center'>
          <div className='-bottom-[150px] absolute'>
            <Alerts
              alertHeader={alertDetails.messageHeader}
              alertBody={alertDetails.messageBody}
              className='w-[400px]'
              onCloseIconClick={() => {
                setAlertDetails({
                  ...alertDetails,
                  isActive: false,
                })
              }}
              status='Error'
            />
          </div>
        </RowWithAlignment>
      )}
    </div>
  )
}

LogInForm.defaultProps = {}

export default LogInForm
