import { Badge } from '@labourhub/labour-hub-ds'

type CreditListItemProps = {
  title: string
  data?: any
  iconClass?: string
}

export const CreditListItem = ({
  title,
  data,
  iconClass,
}: CreditListItemProps) => {
  const badgeTheme =
    data === 'RESPONSE_RECEVIED' || data === true
      ? 'green'
      : data === 'AWAITING_RESPONSE'
      ? 'yellow'
      : data === 'RESPONSE_IN_REVIEW'
      ? 'yellow'
      : data === 'RESPONSE_EVALUATED'
      ? 'blue'
      : data === 'REQUEST_DECLINED' || data === false
      ? 'red'
      : 'gray'
  return (
    <div className={`flex items-center ${!iconClass && 'py-2'}`}>
      <div className='flex flex-row items-center gap-2'>
        {iconClass && (
          <i className={`${iconClass} text-Gray-500 text-[20px]`} />
        )}
        <h3 className='text-Gray-500 text-small'>{title}</h3>
      </div>
      <div className='ml-auto text-Gray-900 text-small font-Medium'>
        {data == true ||
        data == false ||
        data === 'RESPONSE_RECEVIED' ||
        data === 'AWAITING_RESPONSE' ||
        data === 'RESPONSE_IN_REVIEW' ||
        data === 'RESPONSE_EVALUATED' ||
        data == 'REQUEST_DECLINED' ? (
          <Badge
            size='large'
            theme={badgeTheme}
            isDot={true}
            isCustomStyles={true}
            className='flex items-center justify-center w-fit py-[2px] font-Medium bg-none'>
            <div className='text-extra-small font-Medium leading-3'>
              {data === true
                ? 'Yes'
                : data == false
                ? 'No'
                : data === 'RESPONSE_RECEVIED'
                ? 'Recevied'
                : data === 'AWAITING_RESPONSE'
                ? 'Awaiting'
                : data === 'REQUEST_DECLINED'
                ? 'Declined'
                : data === 'RESPONSE_EVALUATED'
                ? 'Evaluated'
                : data === 'RESPONSE_IN_REVIEW'
                ? 'Review'
                : 'Not Selected'}
            </div>
          </Badge>
        ) : (
          data
        )}
      </div>
    </div>
  )
}
