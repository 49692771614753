import { useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Button, Modal } from '@labourhub/labour-hub-ds'
import ProgressBar from '@ramonak/react-progress-bar'
import importFailureAnimation from 'assets/animations/error-red.json'
import importSuccessAnimation from 'assets/animations/import-success.json'
import candidateCSVImportBG from 'assets/images/csv/candidate-csv-import.png'
import employeeCSVImportBG from 'assets/images/csv/employee-csv-import.svg'
import CN from 'classnames'
import { candidateCSV, employeeCSV } from 'features/candidates/data'
import { useImportCandidateFromCSV } from 'framework/api/methods'
import { useLottie } from 'lottie-react'

import { CSVDropZone } from './CSVDropZone'

export type CandidateImportWizardProps = {
  isOpenImportWizard: boolean
  isEmployee: boolean
  setIsOpenImportWizard: (status: boolean) => void
  refetchList: () => void
}

export const CandidateImportWizard = ({
  isOpenImportWizard,
  isEmployee,
  setIsOpenImportWizard,
  refetchList,
}: CandidateImportWizardProps) => {
  const [stepperIndex, setStepperIndex] = useState(0)
  const [csvFile, setCSVFile] = useState<File>()

  const csvLinkElement = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const {
    mutate: importCandidateFromCSV,
    progress,
    errorMsg,
    sucessfulRecordCount,
  } = useImportCandidateFromCSV(csvFile, isEmployee, () => {
    setStepperIndex(2)
  })

  const SuccessAnimation = () => {
    const { View } = useLottie({
      animationData: importSuccessAnimation,
      loop: true,
      autoplay: true,
      width: 92,
      height: 92,
    })

    return View
  }

  const ErrorAnimation = () => {
    const { View } = useLottie({
      animationData: importFailureAnimation,
      loop: true,
      autoplay: true,
      width: 92,
      height: 92,
    })

    return View
  }

  const tryAgain = () => {
    setStepperIndex(0)
  }

  const resetWizard = () => {
    setStepperIndex(0)
    setCSVFile(undefined)
    refetchList()
  }
  const applicant = useMemo(
    () => (!isEmployee ? 'Candidate' : 'Employee'),
    [isEmployee],
  )

  return (
    <div>
      {/* Delete Referee modal */}
      <Modal
        headerTitle={`Import ${applicant}s (.csv)`}
        customWidth='70%'
        isActive={isOpenImportWizard}
        onClickPrimaryBtn={() => {
          setStepperIndex(stepperIndex + 1)
        }}
        onOverlayClick={() => {
          setIsOpenImportWizard(false)
          resetWizard()
        }}
        onHeaderCloseButtonClick={() => {
          setIsOpenImportWizard(false)
          resetWizard()
        }}
        isFooterShow={false}
        primaryButtonProps={{ className: 'absolute right-4 bottom-3' }}>
        {/* for csv export */}
        <CSVLink
          filename={`${applicant.toLowerCase()}-list.csv`}
          data={isEmployee ? employeeCSV : candidateCSV}
          ref={csvLinkElement}
        />
        <div className='w-full box-border p-4 min-h-[638px]'>
          <div className='flex justify-center items-center px-3 py-4 gap-2 mb-6 bg-Gray-50'>
            <div className='flex justify-center items-center gap-2'>
              {stepperIndex !== 0 ? (
                <span
                  className={
                    'flex flex-col justify-center items-center w-8 h-8 rounded-full  text-small font-Medium text-Cobalt-600 shadow-md bg-Cobalt-500'
                  }>
                  <i className='ri-check-line text-small text-white' />
                </span>
              ) : (
                <span
                  className={CN(
                    'flex flex-col justify-center items-center w-8 h-8 rounded-full  text-small font-Medium text-Cobalt-600 shadow-md bg-white',
                    { 'border-2 border-Cobalt-600': stepperIndex === 0 },
                  )}>
                  1
                </span>
              )}
              <span
                className={CN('text-small text-Gray-500 font-Medium', {
                  'text-Cobalt-600': stepperIndex === 0,
                })}>
                Upload CSV file
              </span>
            </div>
            <hr
              className={CN('border-t-2 border-Cobalt-600 w-[100px]', {
                'border-dashed': stepperIndex === 0,
              })}
            />
            <div className='flex justify-center items-center gap-2'>
              {stepperIndex > 1 ? (
                <span
                  className={
                    'flex flex-col justify-center items-center w-8 h-8 rounded-full  text-small font-Medium text-Cobalt-600 shadow-md bg-Cobalt-500'
                  }>
                  <i className='ri-check-line text-small text-white' />
                </span>
              ) : (
                <span
                  className={CN(
                    'flex flex-col justify-center items-center w-8 h-8 rounded-full  text-small font-Medium text-Cobalt-600 shadow-md bg-white',
                    { 'border-2 border-Cobalt-600': stepperIndex === 1 },
                  )}>
                  2
                </span>
              )}
              <span
                className={CN('text-small text-Gray-500 font-Medium', {
                  'text-Cobalt-600': stepperIndex === 1,
                })}>
                Import Data
              </span>
            </div>
            <hr
              className={CN('border-t-2 border-Cobalt-600 w-[100px]', {
                'border-dashed': stepperIndex !== 2,
              })}
            />
            <div className='flex justify-center items-center gap-2'>
              {stepperIndex > 1 ? (
                <span
                  className={CN(
                    'flex flex-col justify-center items-center w-8 h-8 rounded-full  text-small font-Medium text-Cobalt-600 shadow-md',
                    { 'bg-Cobalt-500': !errorMsg },
                    { 'bg-Red-500': errorMsg },
                  )}>
                  <i
                    className={CN(
                      'text-small text-white',
                      { 'ri-check-line': !errorMsg },
                      { 'ri-close-line': errorMsg },
                    )}
                  />
                </span>
              ) : (
                <span
                  className={CN(
                    'flex flex-col justify-center items-center w-8 h-8 rounded-full  text-small font-Medium text-Cobalt-600 shadow-md bg-white',
                    { 'border-2 border-Cobalt-600': stepperIndex === 1 },
                  )}>
                  3
                </span>
              )}
              <span
                className={CN(
                  'text-small text-Gray-500 font-Medium',
                  {
                    'text-Cobalt-600': stepperIndex === 2 && !errorMsg,
                  },
                  {
                    'text-Red-500': stepperIndex === 2 && errorMsg,
                  },
                )}>
                Done
              </span>
            </div>
          </div>

          {/* Stepper Details */}

          {stepperIndex === 0 && (
            <>
              <div className='flex flex-col p-4 bg-Cobalt-50 rounded-lg w-full mb-8'>
                <span className='text-heading-4 font-Medium text-Gray-800 mb-1'>
                  Import {applicant}s with a CSV File
                </span>
                <span className='text-base font-Regular text-Gray-500 mb-8'>
                  Match your fields according to the figure below. You can
                  download the sample template below as well.
                </span>
                <img
                  src={isEmployee ? employeeCSVImportBG : candidateCSVImportBG}
                  alt='csv-upload'
                />
                <div>
                  <Button
                    textTheme='blue'
                    size='sm'
                    theme='white'
                    className='mt-2'
                    onClick={() => {
                      csvLinkElement?.current?.link.click()
                    }}
                    iconLeft={<i className='ri-global-line text-small' />}>
                    <span className='text-small font-Medium'>
                      Download the template
                    </span>
                  </Button>
                </div>
              </div>
              <>
                <div className='text-base font-Medium text-Gray-800'>
                  Select CSV file from your device
                </div>
                <div className='text-extra-small font-Regular text-Gray-600 mb-3'>
                  Max file size 100MB
                </div>
              </>
              <CSVDropZone
                setCSVFile={(file) => {
                  if (file) {
                    setCSVFile(file)
                  }
                }}
              />
            </>
          )}
          {stepperIndex === 1 && (
            <div className='flex justify-center'>
              <div className='w-[500px]'>
                <div className='text-heading-4 text-Gray-800 font-Medium'>
                  Importing
                </div>
                <div className='text-base text-Gray-500 font-Regular mb-8'>
                  Your data is being imported...
                </div>
                <ProgressBar
                  completed={progress || 1}
                  labelClassName={'hidden'}
                  bgColor={'#1E75C6'}
                />
              </div>
            </div>
          )}
          {stepperIndex === 2 && (
            <>
              {!errorMsg ? (
                <div className='flex flex-col justify-center items-center'>
                  <div className='flex justify-center w-[92px] h-[92px]'>
                    <SuccessAnimation />
                  </div>
                  <div className='text-heading-4 text-Gray-800 font-Medium'>
                    Import is Complete
                  </div>
                  <div className='text-base text-Gray-500 font-Regular mb-8'>
                    {applicant}s data has been successfully imported.
                  </div>

                  <Button
                    textTheme='blue'
                    size='sm'
                    theme='white'
                    onClick={() => {
                      setIsOpenImportWizard(false)
                      resetWizard()
                    }}
                    className='min-w-[500px] mb-8'>
                    <i className='ri-global-line text-small mr-2' />
                    <span className='text-small font-Medium'>
                      See {applicant} List
                    </span>
                  </Button>
                  <div className='w-full bg-Blue-50 p-4 border border-Blue-300'>
                    <div className='flex gap-2'>
                      <i className='ri-information-fill text-Blue-600' />
                      <div className='flex flex-col'>
                        <div className='font-Medium text-Blue-800'>
                          You have successfully imported {` `}
                          {`${sucessfulRecordCount} ${applicant.toLowerCase()}s.`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col justify-center items-center'>
                  <div className='flex justify-center w-[92px] h-[92px]'>
                    <ErrorAnimation />
                  </div>
                  <div className='text-heading-4 text-Gray-800 font-Medium'>
                    Import has some errors
                  </div>
                  <div className='text-base text-Gray-500 font-Regular mb-8'>
                    The file you uploaded has some formatting issues. You may
                    fix the errors and try the upload again.
                  </div>

                  <Button
                    textTheme='blue'
                    size='sm'
                    theme='white'
                    className='w-[263px] mb-8'
                    onClick={() => {
                      tryAgain()
                    }}>
                    <span className='text-small font-Medium'>Try Again</span>
                  </Button>
                  {/* Error Content */}
                  <div className='w-full bg-Red-50 p-4 border border-Red-300 mb-4 max-h-[250px] overflow-y-auto styled-scroll'>
                    <div className='flex gap-2'>
                      <i className='ri-close-circle-fill text-Red-500' />
                      <div className='flex flex-col'>
                        <div className='font-Medium text-Red-800'>
                          Following Errors Occurred!
                        </div>
                        <div className='text-small text-Red-700 pl-5 pt-1'>
                          {errorMsg.map((errorString, index) => (
                            <span key={index} className='list-item'>
                              <span className='break-all line-clamp-2'>
                                {errorString}
                              </span>
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {stepperIndex === 0 && (
            <Button
              textTheme='white'
              size='md'
              className='absolute right-4 bottom-3'
              isDisabled={!csvFile}
              onClick={() => {
                if (csvFile) {
                  setStepperIndex(1)
                  importCandidateFromCSV()
                }
              }}>
              Import File
            </Button>
          )}
        </div>
      </Modal>
    </div>
  )
}
